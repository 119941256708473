import * as React from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import * as types from 'src/types';
import * as scss from './button.module.scss';

export type Ref = HTMLButtonElement & HTMLAnchorElement;

export type Props = {
  children?: React.ReactNode | string;
  href?: string;
  icon?: IconProp;
  iconAlign?: 'left' | 'right';
  iconClass?: string;
  inputType?: types.ButtonInputType;
  label?: string | React.ReactNode;
  title?: string;
  disabled?: boolean;
  mode?: types.Theme;
  outline?: boolean;
  overlayTitle?: string;
  size?: types.Size;
  style?: React.CSSProperties;
  tag: 'button' | 'a';
  type?: types.ButtonTypeKeys;
  variant?: types.Variant;
} & React.HTMLAttributes<HTMLButtonElement> &
  React.HTMLAttributes<HTMLAnchorElement>;

export const Button = React.forwardRef<Ref, Props>(
  ({ onClick, label, style, iconClass, ...props }, ref) => {
    const isAnchor = props.tag === 'a';
    const isButton = props.tag === 'button';
    const isExternalAnchor = isAnchor && 'external' === props.type;
    const Component = props.tag;
    const theProps = {
      href: isAnchor ? props.href : undefined,
      type: isButton ? props.inputType : undefined,
      title: props.title,
      target:
        isAnchor && props.type
          ? props.type === 'internal' || props.type === 'overlay'
            ? types.ButtonTypes['internal']
            : types.ButtonTypes[props.type]
          : undefined,
      className: clsx(
        scss.btn,
        'ring',
        {
          [`btn--${props.size}`]: !!props.size,
          [`btn--icon-${props.iconAlign}`]: !!props.icon,
          [`btn--${props.variant}-${props.mode}`]: !!props.variant,
          'btn--outline': props.outline,
        },
        props.className
      ),
      disabled: props.disabled,
      'aria-disabled': props.disabled,
      rel: isExternalAnchor ? 'noreferrer' : undefined,
      style,
    };

    return (
      <Component ref={ref} onClick={onClick} {...theProps}>
        <span className={clsx(scss.label)}>{label}</span>
        {!!props.icon && (
          <span className={clsx(scss.icon, iconClass)}>
            <FontAwesomeIcon icon={props.icon} />
          </span>
        )}
      </Component>
    );
  }
);

Button.defaultProps = {
  iconAlign: 'right',
  mode: 'dark',
  outline: false,
  tag: 'button',
  variant: 'primary',
};
