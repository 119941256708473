import * as React from 'react';
import clsx from 'clsx';
import { Button } from 'src/components/ui/button/button';

import * as scss from './Error404.module.scss';

export const Error404: React.FC = () => {
  return (
    <div className={clsx(scss.wrapper)}>
      <div className={clsx(scss.content)}>
        <span className={clsx(scss.content404)}>404</span>
        <h1 className={clsx(scss.contentHeading)}>Page not found</h1>
        <p className={clsx(scss.contentDescription)}>
          The page you are looking for doesn’t exist.
        </p>
        <div className={clsx(scss.contentCta)}>
          <Button
            tag={`a`}
            href="/"
            label={`Return Home`}
            mode={`light`}
            outline={true}
          />
        </div>
      </div>
    </div>
  );
};
