import * as React from 'react';
import { PageLayout as Layout } from '../components/Layouts/PageLayout/PageLayout';
import { Seo } from '../components/Seo/Seo';
import { Error404 } from '../components/Error404/Error404';

const NotFoundPage: React.FC = () => {
  return (
    <>
      <Layout className="bg bg-404">
        <Seo title="Page not found" />
        <Error404 />
      </Layout>
    </>
  );
};

export default NotFoundPage;
